<template>
  <div class="dashboard-container" v-loading="loading">
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formData"
        :rules="rules"
        label-width="200px"
      >
        <el-form-item label="标题名称" prop="title">
          <el-input
            v-model="formData.title"
            placeholder="请输入标题名称"
            style="width: 50%"
          />
        </el-form-item>
      
       <el-form-item label="分类类型" prop="typeId">
          <el-select
            v-model="formData.typeId"
            placeholder="请选择分类"
            @change="selectChange"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.typeName"
              :value="item.typeId"
            >
            </el-option>
          </el-select>
        </el-form-item>

 <el-form-item label="浏览权限" prop="browsePermission">
         <el-select
              v-model="formData.browsePermission"
              placeholder="请选择浏览权限"
              @change="change"
            >
              <el-option
                v-for="(item, index) in res"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
     </el-form-item>

      <el-form-item label="上传PDF文件" prop="pdfFid" v-if="!formData.pdfId">
         <el-upload
    class="upload-demo"
    action="http://up-z2.qiniup.com"
     ref="up"
     accept='.pdf'
      :on-preview="handlePreview"
     :on-success="handleAvatarSuccess"
            :data="postData"
            :before-upload="beforeAvatarUpload"
  >
    <el-button type="primary">点击上传</el-button>
    <template #tip>
      <div class="el-upload__tip">
        只能上传pdf类型的文件
      </div>
    </template>
  </el-upload>
        
        </el-form-item>
        <el-form-item label="排序" prop="sort" v-if="formData.pdfId">
          <el-input v-model="formData.sort" style="width: 30%" />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
<el-dialog title="添加PDF分类" :visible="dialog" @close="btnCancel1">
      <!-- 表单 -->
      <el-form
        ref="pForm"
        :model="fdata"
        :rules="rules1"
        label-width="200px">
        <el-form-item label="标题名称" prop="typeName">
          <el-input
            v-model="fdata.typeName"
            placeholder="请输入标题名称"
            style="width: 50%"
          />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK1">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel1">取消</el-button>
        </el-col>
        
      </el-row>
      
    </el-dialog>
    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="12" align="left">
          <div style="display: flex; width: 60%">
            <el-select
              v-model="typeId"
              placeholder="请选择PDF分类"
              @change="selectChange2"
              style="wdith: 300px; margin-right: 10px"
            >
              <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.typeName"
                :value="item.typeId"
              >
              </el-option>
            </el-select>
            <el-input
              v-model="searchtitle"
              placeholder="请输入标题名称"
              style="width: 50%"
            ></el-input>
            <el-button
              type="primary"
              slot="append"
              @click="searchgetPDFInfoList"
              style="margin-left: 5px"
              >查询</el-button
            >
          </div>
        </el-col>
        <el-col :span="12" align="right">
            <el-button class="el-icon-edit" type="warning" @click="addfl"
            >管理PDF分类</el-button
          >
          <el-button class="el-icon-edit" type="primary" @click="addSchoolInfo"
            >添加PDF</el-button
          >
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
        <el-table-column
          align="center"
          prop="title"
          label="标题"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="fileSize"
          label="文件大小"
          :show-overflow-tooltip="true"
        />
<el-table-column
          align="center"
          prop="browsePermission"
          label="浏览权限"
          :show-overflow-tooltip="true"
        >
         <template slot-scope="scope">
            {{ scope.row.browsePermission| sp }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="pdfToImgStatus"
          label="PDF转图片状态"
          :show-overflow-tooltip="true"
        >
         <template slot-scope="scope">
            {{ scope.row.pdfToImgStatus |spdf }}
          </template>
        </el-table-column>
       
        <el-table-column
          align="center"
          prop="totalPage"
          label="总页数"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="viewNum"
          label="阅读数"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="collectNum"
          label="收藏数量"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="downloadNum"
          label="下载次数"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="typeName"
          label="分类名称"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
       
        <el-table-column
          align="center"
          prop="upStatus"
          label="上下架状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.upStatus | fmatStatus }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="sort"
          label="排序"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | getLocalTime }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" width="251px">
          <template slot-scope="scope">
         
            <el-button
              type="text"
              size="small"
              @click="setPDFInfoStatus(scope.row)"
            >
              {{ scope.row.upStatus == 1 ? "上架" : "下架" }}
            </el-button>

            <el-button
              type="text"
              size="small"
              @click="updateSchoolInfo(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="delPDFInfo(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getPDFInfoList"
      />
    </div>
  </div>
</template>

<script>
import {
  addType,
  getUploadToken,
  getTypes,
  getPDFInfoList,
  addPDFInfo,
  setPDFInfoStatus,
 modifyPDFInfo,
  delPDFInfo,
} from "../../request/http";

export default {
  data() {
    return {
        dialog: false,
      fdata: {
        typeName: "",
        typeGroup: "PDF",
      },
      typeId: "",
      title: "", //弹出框标题
      loading: true, //加载动画
      showDialog: false, //显示隐藏
      list: "", // 列表
      options: [],
      res:[ {
            label:'所有人',
            value:1
          },
          {
            label:'会员专属',
            value:2

          }],
      total: 0, //总条数
       postData: {
        token: "",
        key: "", //上传的Logo
      },
      queryParams: {
        page: 1,
        pageSize: 10,
      },
      formData: {
        pdfFid:'',
        typeId:'',
        title: "",
        browsePermission:""
      },
      searchtitle: "", //搜索名称
      rules: {
        title: [
          { required: true, message: "标题名称不能为空", trigger: "blur" },
        ],
         browsePermission: [
          { required: true, message: "浏览权限不能为空", trigger: "blur" },
        ],
       pdfFid: [
        
           {
            required: true,
            message: "请上传符合pdf格式的文件",
            trigger: "blur",
          },
        ],
          typeId: [
          { required: true, message: "分类Id不能为空", trigger: "blur" },
        ],
     sort: [
          { required: true, message: "排序不能为空", trigger: "blur" },
        ],
      },
       rules1: {
        typeName: [
          { required: true, message: "分类名称不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getPDFInfoList();
   this.getList()
  },
  methods: {
    handlePreview(file) {
 
      if (this.formData.pdfFid) {
        window.open(
          "http://img.c-fol.cn/" + this.formData.pdfFid,
          "_blank"
        );
      } else {
        this.$message.warning("暂不支持预览");
      }
},

      addfl(){
         this.$router.push({
        path: "/managementpdf",
       
      });
     },
     btnOK1() {
      this.$refs.pForm
        .validate()
        .then(() => {
            return addType(this.fdata);
        })
        .then(() => {
          //  提示消息
            this.$message.success("添加分类成功");
            this.getList();
         

          this.dialog = false;
        });
    },
 btnCancel1() {
      this.fdata = {};
      this.$refs.pForm.resetFields();
      this.dialog =false
    },

   async getList() {
      let res = await getTypes({
        typeGroup: "PDF",
      });
      console.log(res);

      this.options = res.data;
    },

    async selectChange2(val) {
      this.queryParams.typeId = val;
      let { data, count } = await getPDFInfoList(this.queryParams);
      this.list = data;
      this.total = count;
    },
    selectChange(value) {
      this.formData.typeId = value;
      console.log(this.formData.typeId);
    },
    change(val){
this.formData.browsePermission = val;
      console.log(this.formData.browsePermission);
    },
 
    async setPDFInfoStatus(row) {
    
      if(row.pdfToImgStatus==3){
 let id = row.pdfId;
      let res = await setPDFInfoStatus({ pdfId: id });
      console.log(res.code);
      res.code == 0 &&
        this.$message({
          type: "success",
          message: "设置成功",
        });
        //  this.getPDFInfoList();
      }
     else { 
       this.$message({
          type: "warning",
          message: "PDF正在转换中,请稍后",
        });
      // this.getPDFInfoList();
     }
        this.getPDFInfoList();
    },
    //获取列表
    async getPDFInfoList() {
      let { data, count } = await getPDFInfoList(this.queryParams);
      this.list = data;
      this.total = count;
      console.log(this.list);
      this.loading = false;
    },
    //查询
    async searchgetPDFInfoList() {
      this.queryParams = {
        page: 1,
        pageSize: 10,
        title: this.searchtitle,
      };

      let { data, count } = await getPDFInfoList(this.queryParams);
      this.list = data;
      this.total = count;
    },
     // 上传方法
    handleAvatarSuccess(res, file) {
      console.log("上传成功", res, file);
      this.formData.pdfFid =  res.key;
      
    },
    async beforeAvatarUpload(file) {
      // console.log(file);
      const isLt30M = file.size / 1024 / 1024 < 30;
                     if (!isLt30M) {
                             this.$message.error('上传PDF的大小不能超过 30M!');
                     }

    else{
        let { data } = await getUploadToken();
      // console.log(data);
      if (data == "" || data == null) {
        this.message.error("上传文件时获取Token失败！");
        return;
      }
      this.postData.token = data.token;
      this.postData.key = data.fid;
    }
    },
   
    // 打开添加的窗口
    addSchoolInfo() {
      // this.imageUrl=null
       
      this.title = "添加PDF信息";
      this.showDialog = true;
    },
    // 打开编辑类别的窗口
    updateSchoolInfo(val) {
      console.log(val);
      this.title = "编辑PDF信息";
      this.formData = {
        sort: val.sort,
        pdfId: val.pdfId,
        pdfFid:val.pdfFid,
        title: val.title,
        typeId: val.typeId,
        browsePermission:val.browsePermission
     
      };
      this.showDialog = true;
    },

    // 确认
    btnOK() {
      this.$refs.perForm
        .validate()
        .then(() => {
          if (this.formData.pdfId) {
            return modifyPDFInfo(this.formData);
          } else {
            return addPDFInfo(this.formData);
          }
        })
        .then(() => {
          //  提示消息
          if (this.formData.pdfId) {
            this.$message.success("编辑成功");
          } else {
            this.$message.success("新增成功");
            this.$refs.up.clearFiles()
          }

          this.getPDFInfoList();

          this.showDialog = false;
        });
    },

    //弹窗取消函数
    btnCancel() {
  // if(this.formData.pdfFid!==''){
  //   this.$refs.up.clearFiles()
  // }
      this.formData = {};
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },
    //删除信息
    async delPDFInfo(row) {
      try {
        await this.$confirm("确定要删除该PDF吗");
        await delPDFInfo({ pdfId: row.pdfId });
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        );
        this.queryParams.page =
          this.queryParams.page > totalPage ? totalPage : this.queryParams.page;
        this.queryParams.page =
          this.queryParams.page < 1 ? 1 : this.queryParams.page;
        this.getPDFInfoList();

        this.$message.success("删除成功");
      } catch (error) {
        console.log(error);
      }
    },

  
   
    
  },
};
</script>

<style>
</style>